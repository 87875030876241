

@mixin EighteenSize {
    width: 20px;
    height: 20px;
    background-size: 18px;
    flex: 0 0 auto;
}

@mixin TwentyFive {
    width: 30px;
    height: 30px;
    background-size: 25px;
    background-position: center;
    flex: 0 0 auto;
}

@mixin searchIcon {
    background: url("../../assets/icons/search_gray.png") no-repeat center;
    background-size: 15px;
}



.menuIcon {
    background: url("../../assets/icons/search_gray.png") no-repeat center;
    @include EighteenSize();
}



footer {
    .globe {
        background-image: url("../../assets/icons/footer.png");
        background-size: 19px;
        width: 20px;
        height: 20px;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 10px;
    }
}

.calendarIcon {
    background: url("../../assets/icons/small-calendar.png") no-repeat center;
    @include EighteenSize();
}

.locationIcon {
    background: url("../../assets/icons/locationPin.png") no-repeat center;
    @include EighteenSize();
}
.DroplocationIcon {
    background: url("../../assets/icons/drop_location.png") no-repeat center;
    @include EighteenSize();
}
.errorIcon {
    background: url("../../assets/icons/error.png") no-repeat center;
    background-size: 23px;
    width: 25px;
    height: 25px;
}

.decrement {
    background-image: url("../../assets/icons/minus.png");
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.increment {
    background-image: url("../../assets/icons/plus.png");
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.socialIcon {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 30px;

    &.fb {
        background-image: url("../../assets/icons/social/facebook.png");
    }

    &.google {
        background-image: url("../../assets/icons/social/google-1.png");
        margin: 0 15px;
    }
    &.instagram {
        background-image: url("../../assets/icons/social/instagram.png");
        margin: 0 15px;
    }
    &.twitter {
        background-image: url("../../assets/icons/social/twitter.png");
    }
}

.completeIcon {
    background: url("../../assets/icons/tik.png") no-repeat center;
    @include EighteenSize();
}

.starIcon {
    background: url("../../assets/icons/star_color.png") no-repeat center;
    @include EighteenSize();
    background-size: 15px;
    background-position: top;
}

.typingArea {
    .pinIcon {
        background: url("../../assets/icons/clip.png") no-repeat;
        @include TwentyFive();
        cursor: pointer;
    }

    .locationIcon {
        @include TwentyFive();
        cursor: pointer;
    }

}

.ThreeDot {
    background: url("../../assets/icons/menu1.png") no-repeat;
    @include EighteenSize();
    cursor: pointer;
}

.closeBtn {
    // position: absolute;
    // top: 20px;
    // right: 15px;
    background: url("../../assets/icons/left-arrow.png") center / 15px no-repeat transparent;
    border: none;
    width: 15px;
    height: 15px;
    // z-index: 1;
    cursor: pointer !important;
}

.delBtn {
    background: url("../../assets/icons/delete.png") center / 18px no-repeat transparent;
    border: none;
    width: 18px;
    height: 18px;
}

.starFill {
    background-image: url("../../assets/icons/star_color.png");
    background-size: 20px;
    height: 20px;
    width: 20px;
}

.starBorder {
    background-image: url("../../assets/icons/starVoid.png");
    background-size: 20px;
    height: 20px;
    width: 20px;
}

.serviceDetail {

    .rightArrow {
        background: url("../../assets/icons/right-arrow.png") center / 11px no-repeat;
        width: 15px;
        height: 15px;
    }

    .uploadIcon {
        background: url("../../assets/icons/upload.png") center / 20px no-repeat;
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.greenStar {
    background: url("../../assets/icons/star_green_color.png") center / 13px no-repeat;
    width: 15px;
    height: 15px;
    margin: 3px 5px 0 0;
}

.phoneIcon {
    background: url("../../assets/icons/call.png") center / 15px no-repeat;
    width: 15px;
    height: 15px;
}