@import './font';
@import './color';

body {
    font-family: "AvertaRegular";
    font-size: 1rem;
    overflow-x: hidden !important;
    color: $txtClr;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

html {
    scrollbar-width: thin;
}


* {
    outline: none !important;
}

a:hover {
    color: $primaryClr;
}

.primaryClr,
.defaultColor {
    color: $primaryClr;
}

.txtClr {
    color: $txtClr;
}

.lightTxtClr {
    color: $lightTxtClr;
}

.greenTxtClr {
    color: $greenPrimary;
}
.redTxtClr {
    color: $red;
}
.blueTxtClr {
    color: #243fa9;
}

.cursorPointer {
    cursor: pointer;
}

input {
    -webkit-user-select: text;
}

input::placeholder,
.ant-input::placeholder {
    color: #acb2b8;
}

.roundedFivePx {
    border-radius: 5px;
}

.profile {
    object-fit: cover;
    border-radius: 6px;

    &.sm {
        height: 40px;
        width: 55px;
        border-radius: 6px;
        object-fit: cover;
    }

    &.md {
        height: 55px;
        width: 85px
    }
}

.PrimaryBtn {
    background-color: $primaryClr;
    color: $white;
    font-size: 15px;
    border: none;
    padding: 8px 25px !important;
    // height: auto !important;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
    line-height: 23px;
    &:hover {
        background-color: $secoundaryClr;
        color: $primaryClr;

        &:after {
            background: #FFF;
            border: 20px solid #000;
            opacity: 0;
            left: 120%;
            -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
        }
    }

    &:focus {
        background-color: $secoundaryClr;
        color: $primaryClr;
        box-shadow: none;
    }

    &:after {
        background: #FFF;
        border: 0px solid #000;
        content: "";
        height: 155px;
        left: -75px;
        opacity: .8;
        position: absolute;
        top: -50px;
        -webkit-transform: rotate(35deg);
        transform: rotate(35deg);
        width: 50px;
        transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
        /*easeOutCirc*/
        z-index: 1;
    }

    &.lg {
        height: 40px !important;
    }
    &.md {
        height: 32px !important;
    }
}

.SecoundaryBtn {
    background-color: $darkBg;
    color: $white;
    font-size: 15px;
    border: 1px solid $darkBg;
    padding: 6px 35px !important;
    height: auto !important;

    &.lg {
        height: 40px !important;
    }

    &:hover {
        color: $darkBg;
        border: 1px solid $darkBg;
    }
}

.Btns {
    padding: 6px 25px;
    height: auto;

    &.success {
        background-color: $colorEight !important;
        color: $white;
        border: none;

        &:focus {
            background-color: $greenSecoundary !important;
            color: $colorEight;
            border: none;
        }

    }

    &.successLight {
        background-color: #eafee7 !important;
    }

    &.successTxts {
        color: $colorEight !important;
        border: 1px solid $colorSix !important;
    }

    &.Txts {
        color: $colorOne !important;
        border: 1px solid $colorSix !important;
    }

    &.dangerTxts {
        color: red !important;
        border: 1px solid $colorSix !important;
    }

    &.lg {
        height: 40px !important;
    }

}

.dangerBtn {
    background-color: #f11616;
    color: #fff;
    border: none;

    &:hover,
    &:focus {
        background-color: #ffdbdb;
        color: #f11616;
    }
}

.darkBtn,
.darkBtn:focus {
    background-color: #9ca4ab;
    color: #fff;
    border: none;
}

.loadMoreBtn {
    &:hover {
        color: $primaryClr;
    }
}

.flex-1 {
    flex: 1 1 auto;
}

.flex-0 {
    flex: 0 0 auto;
}

.gap-10 {
    gap: 10px;
}

.imgBg {
    background-color: #eeeeee;
}

img {
    object-fit: cover;
    width: 100%;
}

.cardTwoStyle hr:last-child {
    display: none;
}


.ant-btn-submit {
    border: none;
    padding: 0;

    &:hover {
        background-color: transparent;
    }
}

// Ant down arrow style
.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(-45deg) translateX(4px) !important;
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(45deg) translateX(-4px) !important;
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    width: 10px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: $primaryClr !important;
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow {
    transform: rotate(180deg) !important;
}

.ant-form-item-label>label {
    color: $txtClr !important;
}

.ant-form-item-label>label::after {
    content: none;
}

.ant-select-arrow .anticon.anticon-down {
    display: none;
}

.breadcrumbs {
    display: flex;
    flex-wrap: wrap;

    a {
        display: flex;
        align-items: center;
        color: #9ca4ab;
        line-height: 30px;

        .slashIcon {
            background: url("../../assets/icons/right-arrow.png") center / 11px no-repeat;
            width: 15px;
            height: 15px;
        }
    }

    div:last-child .slashIcon {
        display: none;
    }
}

.paymentBox,
.rightSideBox {
    .title {
        background-color: $darkBg;
        color: $white;
        padding: 15px 20px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-bottom: 0;
        font-family: "AvertaSemiBold";
    }

    .content {
        border: 1px solid #dee2e6;
        padding: 20px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.ToggleSwitch {
    position: relative;

    &.ToggleSwitch__rounded {
        .Slider {
            border-radius: 15rem;
            background: #ffe9e2;

            &:before {
                border-radius: 50%;
            }
        }
    }

    .ToggleSwitch__wrapper {
        position: relative;
        width: 48px;
        height: 26px;

        .Slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: .4s ease;

            &:before {
                width: 17px;
                height: 17px;
                position: absolute;
                background: #fff;
                content: '';
                margin: 0;
                padding: 0;
                top: 46%;
                left: 0.4rem;
                transform: translateY(-50%);
                transition: .4s;
                cursor: pointer;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            }

            &.isChecked {
                background: $primaryClr;

                &:before {
                    left: calc(100% - 0.10rem - 1.3rem);
                    background: $white;
                }
            }
        }
    }
}

.ant-select-show-search {
    svg {
        display: none !important;
    }
}


// july 15 sj
.d-grid{
    display: grid;
}

.place-items-center{
    place-items: center;
}
.vh-50 {
    height: 50vh;
}
.vh-75 {
    height: 50vh;
}

.op-30{
    opacity: 0.3;
}

.white-nowrap{
    white-space: nowrap;
}
.text-danger {
    margin: 5px 0;
}
.swal2-icon.swal2-warning {
    border-color: #f14e15;
    color: #f14e15;
}


.w-sm-auto{
    @media(min-width:575px){
    width: auto !important;
    }
}