$primaryClr:#ee2436;
$secoundaryClr: #feede7;
$white: #fff;

// Text colors
$txtClr:#314659;
$darkTxtClr: #0d1a26;
$lightTxtClr: #9ca4ab;
$green:#228d27;
$red: #ee2436;

// Additional color 
$greenPrimary: #4ab739;
$greenSecoundary: #eafee7;
$darkBg: #444444;

$colorOne:#ee2436;
$colorTwo: #feede7;
$colorThree: #314659; //Font
$colorFour: #eeeeee;
$colorFive : #606060; //Mainly PlaceHolders
$colorSix: #dee2e6; // Borders
$colorSeven: #fff;
$colorEight: #35ba42;
$colorNine: #f8f8f8;